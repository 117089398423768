import apiReducer from "./apiReducer";
import globalReducer from "./globalReducer";
import loginReducer from "./loginReducer";
import profileReducer from "./profileReducer";
import { combineReducers } from "redux";
import cartReducer from "./cartReducer";

const rootReducer = combineReducers({
  profile: profileReducer,
  apiData: apiReducer,
  globalData: globalReducer,
  loginData: loginReducer,
  cart: cartReducer
});

export default rootReducer;
