// import { createStore, applyMiddleware } from "redux";
// import { composeWithDevTools } from "redux-devtools-extension";
// import { persistReducer } from "redux-persist";
// import AsyncStorage from '@react-native-async-storage/async-storage';
// import thunkMiddleware from "redux-thunk";
// import rootReducer from "./reducers/rootReducer";

// const persistConfig = {
//   key: "primary",
//   storage: AsyncStorage,
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer);

// export function initializeStore() {
//   return createStore(
//     persistedReducer,
//     composeWithDevTools(applyMiddleware(thunkMiddleware))
//   );
// }


import {createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import rootReducer from "./reducers/rootReducer";

const initialState = {
    
};
const middleware = [thunk];

// ---Redux DevTools
let composeEnhancers = compose;
if (typeof window !== 'undefined') {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middleware),
  //  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    ),
);
export default store;

// import { configureStore, createSlice } from "@reduxjs/toolkit";
// import authReducer from "./services/Auth";

// const store = configureStore({
//   reducer: {
//     // auth: authReducer
//   },
// });

// export default store;