import { Fragment, useEffect } from "react";
import Head from "next/head";
import { Provider } from "react-redux";
import store from "../redux/store";
import "../assets/scss/style.scss";
import "../assets/css/globals.css";
import AOS from "aos";
import "aos/dist/aos.css";
import "react-loading-skeleton/dist/skeleton.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Toaster } from "react-hot-toast";
import "../assets/css/react-toggle.css";
import { useRouter } from "next/router";
import Script from "next/script";
import "../assets/css/workshop-detail.css";
import "../assets/css/dashboard.css";
import "../assets/css/common.css";

const MyApp = ({ Component, pageProps }) => {
  const router = useRouter();

  useEffect(() => {
    if (AOS) {
      AOS?.init({
        delay: 400,
        duration: 800,
      });
    }
  }, []);
  useEffect(() => {
    if (!process.env.NEXT_PUBLIC_IS_DEV) {
      const originalConsoleLog = console.log;
      const originalConsoleWarn = console.warn;
      const originalConsoleError = console.error;
      console.log = function () {};
      console.warn = function () {};
      console.error = function () {};
    }
  }, []);

  return (
    <Fragment>
      <Head>
        {router.pathname !== `/services/[...slug]` ? (
          <>
            <title> Open Bonnet </title>
            <meta
              name="description"
              content="Book your Car Service & Repair with UAE's Widest Network of Expert Service Providers. Let the Award-Winning OB Digital Platform Enhance your Service Experience"
            />{" "}
          </>
        ) : null}
        <meta
          name="keywords"
          content="UAE car service, car repairs Dubai UAE, automotive services UAE, car maintenance UAE, car oil change Sharjah UAE, Car Inspection Abu Dhabi."
        />
        {router.pathname !== "/sign-up" ? (
          <meta property="og:title" content="Open Bonnet" />
        ) : null}
        {router.pathname !== "/sign-up" ? (
          <meta
            property="og:description"
            content="Open Bonnet is the most trusted and largest car repair and Service center in UAE. Trust our High-quality service by efficient and experienced mechanics for all your automotive needs."
          />
        ) : null}
        <meta
          property="og:image"
          itemprop="image"
          content="https://openbonnet.com/assets/thumbnail.png"
        />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://openbonnet.com/" />

        <link
          href="https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap"
          rel="stylesheet"
        />

        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
      </Head>{" "}
      <Script
        src="https://code.jquery.com/jquery-3.2.1.slim.min.js"
        integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN"
        crossOrigin="anonymous"
        defer
      />
      <Script
        src="https://cdn.jsdelivr.net/npm/popper.js@1.12.9/dist/umd/popper.min.js"
        integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q"
        crossOrigin="anonymous"
        defer
      />
      <Script
        src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/js/bootstrap.min.js"
        integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl"
        crossOrigin="anonymous"
        defer
      />
      <Script
        src="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.js"
        defer
      />
      {router.pathname !== "/" ? (
        <Script src="https://assets.mamopay.com/public/checkout.min.js" defer />
      ) : null}
      {!process.env.NEXT_PUBLIC_IS_DEV && (
        <>
          <Script>
            {`(function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:3317769,hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
          </Script>
        </>
      )}
      <Toaster
        toastOptions={
          router.asPath == "/checkout"
            ? {
                className: "checkout-page-styles",
                style: {
                  fontSize: 18,
                },
                duration: 2500,
              }
            : {}
        }
      />
      <Provider store={store}>
        <Component {...pageProps} />
      </Provider>
    </Fragment>
  );
};

export default MyApp;
