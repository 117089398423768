export const category = {
  category: [
    {
      ID: 1,
      Name: "Oil Change",
      Slug: "oil-change",
      Description: "OIL CHANGE",
      ImgUrl: "/assets/journey-variant/oil-change.webp",
      JobSectionId: 8,
      JobSectionType: "UFJ",
      IsDisabled: false,
      IsQuotationJourney: false,
      IsPurchaseJourney: true,
    },
    {
      ID: 10,
      Name: "All Repairs",
      Slug: "repair",
      Description: "REPAIR",
      ImgUrl: "/assets/journey-variant/repair.webp",
      JobSectionId: 8,
      JobSectionType: "UFJ",
      IsDisabled: false,
      IsQuotationJourney: false,
      IsPurchaseJourney: true,
    },
    {
      ID: 2,
      Name: "AC Services",
      Slug: "ac-service",
      Description: "AC SERVICE",
      ImgUrl: "/assets/journey-variant/ac-service.webp",
      JobSectionId: 8,
      JobSectionType: "UFJ",
      IsDisabled: false,
      IsQuotationJourney: false,
      IsPurchaseJourney: true,
    },
    {
      ID: 3,
      Name: "Inspection",
      Slug: "inspection",
      Description: "INSPECTION",
      ImgUrl: "/assets/journey-variant/inspection.webp",
      JobSectionId: 8,
      JobSectionType: "UFJ",
      IsDisabled: false,
      IsQuotationJourney: false,
      IsPurchaseJourney: true,
    },
    {
      ID: 17,
      Name: "New Tyres",
      Slug: "tyre",
      Description: "Tyre",
      ImgUrl: "/assets/journey-variant/new-tyre.webp",
      JobSectionId: 8,
      JobSectionType: "UFJ",
      IsDisabled: false,
      IsQuotationJourney: false,
      IsPurchaseJourney: true,
    },
    {
      ID: 9,
      Name: "Wheels & Brakes",
      Slug: "break-and-alignment",
      Description: "break-and-alignment",
      ImgUrl: "/assets/journey-variant/tires-wheels.webp",
      JobSectionId: 8,
      JobSectionType: "UFJ",
      IsDisabled: false,
      IsQuotationJourney: false,
      IsPurchaseJourney: true,
    },
    {
      ID: 7,
      Name: "Car Care",
      Slug: "car-care",
      Description: "CAR CARE & DETAILING",
      ImgUrl: "/assets/journey-variant/car-care.webp",
      JobSectionId: 8,
      JobSectionType: "UFJ",
      IsDisabled: false,
      IsQuotationJourney: false,
      IsPurchaseJourney: true,
    },
    {
      ID: 6,
      Name: "Car Wash At Home",
      Slug: "car-wash-at-home",
      Description: "CAR WASH",
      ImgUrl: "/assets/journey-variant/car-wash-at-home.webp",
      JobSectionId: 8,
      JobSectionType: "UFJ",
      IsDisabled: false,
      IsQuotationJourney: false,
      IsPurchaseJourney: true,
    },
    {
      ID: 8,
      Name: "Battery",
      Slug: "battery",
      Description: "BATTERY",
      ImgUrl: "/assets/journey-variant/battery.webp",
      JobSectionId: 8,
      JobSectionType: "UFJ",
      IsDisabled: false,
      IsQuotationJourney: false,
      IsPurchaseJourney: true,
    },
    {
      ID: 4,
      Name: "Mobile Mechanic",
      Slug: "mobile-mechanic",
      Description: "MOBILE MECHANIC",
      ImgUrl: "/assets/journey-variant/mobile-mechanic.webp",
      JobSectionId: 9,
      JobSectionType: "MOBILE",
      IsDisabled: false,
      IsQuotationJourney: false,
      IsPurchaseJourney: true,
    },
    {
      ID: 5,
      Name: "Breakdown",
      Slug: "breakdown",
      Description: "BREAKDOWN",
      ImgUrl: "/assets/journey-variant/breakdown.webp",
      JobSectionId: 8,
      JobSectionType: "UFJ",
      IsDisabled: false,
      IsQuotationJourney: false,
      IsPurchaseJourney: true,
    },
    {
      ID: 15,
      Name: "Service Contract",
      Slug: "service-contract",
      Description: "PACKAGE",
      ImgUrl: "/assets/journey-variant/service-contract.webp",
      JobSectionId: 11,
      JobSectionType: "PACKAGE",
      IsDisabled: false,
      IsQuotationJourney: false,
      IsPurchaseJourney: true,
    },
  ],
  IsSuccess: false,
  Message: null,
  Error: null,
};
