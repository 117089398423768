import {
  ADDON,
  BATTERY_SELECTION_ITEMS,
  CART_DATA,
  CAR_BRAND_MODAL,
  LOCATION_DATA,
  SELECTED_JOB,
  SERVICE,
  SET_CAR_POPUP,
  SET_LOADER,
  SET_LOGIN_POPUP,
  SET_TOUR_BOX,
  TYRE_SELECTION_ITEMS,
  SET_LAYER,
  TOGGLE_REQUEST_FORM,
  TOGGLE_CHOOSE_CAR,
  TOGGLE_SOS_FORM,
  TOGGLE_SEARCH,
  TOGGLE_SIGNUP,
  TOGGLE_LOGIN,
  TOGGLE_MANAGE_CAR,
  TOGGLE_LOCATION,
  TOGGLE_NOTIFICATION,
  SET_ACCESS_LOCATION,
  SET_SELECTED_ID,
  SET_CURRENT_CATEGORY,
  SET_SAVE_LOCATION,
  SET_OTP_SCREEN,
  SHOW_JOB_SCREEN,
  RESET_STORE,
  TOGGLE_HIDE_HEADER,
  ADD_TO_COMPARE,
  REMOVE_FROM_COMPARE,
  SET_NEW_CAR_SIZE,
  FETCH_LEVEL_UP_MEMBERSHIP_STATUS,
  FETCH_LEVEL_UP_MEMBERSHIP_STATUS_LOADING,
} from "../actions/types";

const initState = {
  carData: null,
  locationData: {
    lat: 25.1287287,
    lng: 55.370041,
    data: {
      Distance: 0,
      Emirate: "DUBAI",
      EmirateArea: "Dubai Silicon Oasis",
      EmirateAreaId: 114,
      EmirateAreaSlug: "dubai-dubai-silicon-oasis",
      EmirateId: 1,
      EmirateSlug: "dubai",
    },
  },
  jobData: [],
  cartData: [],
  service: [],
  addon: [],
  tyreItems: [],
  batteryItems: [],
  showLoginPop: false,
  isLoading: false,
  showCarPop: null,
  isTourOpen: false,
  layer: false,
  requestForm: false,
  locationForm: false,
  LoginForm: false,
  SignupForm: false,
  chooseCarForm: false,
  sosForm: false,
  searchForm: false,
  manageCar: false,
  showNotification: false,
  accessLocation: false,
  selected_id: null,
  currentCategory: [],
  saveLocation: false,
  hideHeader: false,
  otpScreen: false,
  showJobHistoryScreen: false,
  addToCompare: [],
  membershipStatus: null,
  membershipStatusLoading: false,
};

const globalReducer = (state = initState, action) => {
  switch (action.type) {
    case CAR_BRAND_MODAL:
      return {
        ...state,
        carData: action.payload,
      };
    case LOCATION_DATA:
      return {
        ...state,
        locationData: action.payload,
      };
    case SERVICE:
      return {
        ...state,
        service: action.payload,
      };
    case ADDON:
      return {
        ...state,
        addon: action.payload,
      };
    case CART_DATA:
      return {
        ...state,
        cartData: action.payload,
      };
    case TYRE_SELECTION_ITEMS:
      return {
        ...state,
        tyreItems: action.payload,
      };
    case TOGGLE_REQUEST_FORM:
      return {
        ...state,
        requestForm: action.payload,
      };
    case BATTERY_SELECTION_ITEMS:
      return {
        ...state,
        batteryItems: action.payload,
      };
    case SET_NEW_CAR_SIZE:
      return {
        ...state,
        newCarTyerSize: action.payload,
      };
    case SET_LOGIN_POPUP:
      return {
        ...state,
        showLoginPop: action.payload,
      };
    case SET_CAR_POPUP:
      return {
        ...state,
        showCarPop: action.payload,
      };
    case SET_LOADER:
      return {
        ...state,
        isLoading: action.payload,
      };
    case SET_TOUR_BOX:
      return {
        ...state,
        isTourOpen: action.payload,
      };
    case SET_LAYER:
      return {
        ...state,
        layer: action.payload,
      };
    case TOGGLE_CHOOSE_CAR:
      return {
        ...state,
        chooseCarForm: action.payload,
      };
    case TOGGLE_LOCATION:
      return {
        ...state,
        locationForm: action.payload,
      };
    case TOGGLE_LOGIN:
      return {
        ...state,
        LoginForm: action.payload,
      };
    case TOGGLE_SIGNUP:
      return {
        ...state,
        SignupForm: action.payload,
      };
    case TOGGLE_SOS_FORM:
      return {
        ...state,
        sosForm: action.payload,
      };
    case TOGGLE_MANAGE_CAR:
      return {
        ...state,
        manageCar: action.payload,
      };
    case TOGGLE_NOTIFICATION:
      return {
        ...state,
        showNotification: action.payload,
      };
    case TOGGLE_SEARCH:
      return {
        ...state,
        searchForm: action.payload,
      };
    case SET_ACCESS_LOCATION:
      return {
        ...state,
        accessLocation: action.payload,
      };
    case SET_SELECTED_ID:
      return {
        ...state,
        selected_id: action.payload,
      };
    case SET_CURRENT_CATEGORY:
      return {
        ...state,
        currentCategory: action.payload,
      };
    case SET_SAVE_LOCATION:
      return {
        ...state,
        saveLocation: action.payload,
      };
    case SET_OTP_SCREEN:
      return {
        ...state,
        otpScreen: action.payload,
      };
    case TOGGLE_HIDE_HEADER:
      return {
        ...state,
        hideHeader: action.payload,
      };
    case SHOW_JOB_SCREEN:
      return {
        ...state,
        showJobHistoryScreen: action.payload,
      };
    case RESET_STORE:
      return initState;
    case ADD_TO_COMPARE:
      return {
        ...state,
        addToCompare: [...state.addToCompare, action.payload],
      };
    case REMOVE_FROM_COMPARE:
      return {
        ...state,
        addToCompare: state.addToCompare.filter(
          (item) => item !== action.payload
        ),
      };
    case FETCH_LEVEL_UP_MEMBERSHIP_STATUS:
      return {
        ...state,
        membershipStatus: action.payload,
      };
    case FETCH_LEVEL_UP_MEMBERSHIP_STATUS_LOADING:
      return {
        ...state,
        membershipStatusLoading: action.payload,
      };
    default:
      return state;
  }
};

export default globalReducer;
