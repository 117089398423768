import { workshopPurchase } from "../../lib/config";
import {
  APPLY_OB_POINTS,
  FETCH_CART_ADDONS,
  LOADING_PACKAGES,
  RESET_WORKSHOP,
  SET_CART_ADDONS,
  SET_CART_APPLIED_OFFER,
  SET_CART_APPLIED_PICKUP_DROP_PRICE,
  SET_CART_BATTERY_PARAMETER,
  SET_CART_DETAILS,
  SET_CART_JOB_ITEMS,
  SET_CART_JOURNEY,
  SET_CART_MEMBERSHIP,
  SET_CART_PACKAGES,
  SET_CART_PICKUP_DROP_PRICE,
  SET_CART_PRODUCTS,
  SET_CART_PROMOTIONS,
  SET_CART_SELECTED_PICKUP_DROP,
  SET_CART_STAGE,
  SET_CART_TYRE_PARAMETER,
  SET_CART_WORKSHOP,
  SET_OB_POINTS,
  SET_ORDER_SUCCESS,
  SET_SELECTED_QUOTATION_FORM,
  SET_SHOW_MOBILE_ISLAND,
  RESET_CART,
  TOGGLE_MY_ACCOUNT,
  SET_OB_EARN_POINTS,
  SET_OB_BURN_POINTS,
  SET_APPLIED_PROMOTIONS,
  REMOVE_APPLIED_PROMOTIONS,
  ADDDED_PICKUP_DROP_PRICE,
  SHOW_LOADER,
  UPDATE_CART_JOB_ITEMS,
  UPDATE_CART_PRODUCTS,
  FORMAT_RESET_STORE,
  SET_APPLIED_CART_OFFERS,
  SET_SELECTED_AREA,
} from "../actions/types";

const initState = {
  stage: null,
  workshop: null,
  products: [],
  jobItems: [],
  addons: null,
  addedAddons: [],
  addMembership: false,
  details: null,
  promotions: [],
  appliedOffer: null,
  appliedCartOffers: null,
  pickupDropPrice: null,
  appliedPickupDropPrice: 0,
  appliedPromotions: {},
  addedPackages: [],
  obEarnPoints: {},
  obBurnPoints: {},
  selectedPickupDrop: null,
  journeyType: workshopPurchase,
  tyreQuotation: {
    width: null,
    height: null,
    rim: null,
    isProceed: false,
    data: null,
  },
  batteryQuotation: {
    type: null,
    isProceed: false,
    data: null,
  },
  obPoints: null,
  isRedeemed: null,
  orderSuccess: null,
  selectedQuotationForm: null,
  showMobileIsland: false,
  loadingPackages: false,
  addedPickupDropPrice: false,
  showLoader: false,
  selectedArea:null
};

const cartReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_CART_STAGE:
      return {
        ...state,
        stage: action.payload,
      };
    case SET_CART_WORKSHOP:
      return {
        ...state,
        workshop: action.payload,
      };
    case SET_CART_PRODUCTS:
      return {
        ...state,
        products: action.payload,
      };
    // case FETCH_CART_ADDONS:
    //     let addons = state.addons != null ? [...new Set([...state.addons, ...action.payload])] : action.payload
    //     addons = addons.filter((value, index, self) =>
    //         index === self.findIndex((t) => (
    //             t.AddonId === value.AddonId
    //         ))
    //     )
    //     return {
    //         ...state,
    //         addons
    //     }
    case FETCH_CART_ADDONS:
      return {
        ...state,
        addons: action.payload,
      };
    case SET_CART_ADDONS:
      return {
        ...state,
        addedAddons: action.payload,
      };
    case SET_CART_MEMBERSHIP:
      return {
        ...state,
        addMembership: action.payload,
      };
    case ADDDED_PICKUP_DROP_PRICE:
      return {
        ...state,
        addedPickupDropPrice: action.payload,
      };
    case SET_CART_JOB_ITEMS:
      return {
        ...state,
        jobItems: action.payload, //[...state.jobItems, ...action.payload]
      };
    case SET_CART_DETAILS:
      return {
        ...state,
        details: action.payload,
      };
    case SET_CART_PROMOTIONS:
      return {
        ...state,
        promotions: action.payload,
      };
    case SET_CART_APPLIED_OFFER:
      return {
        ...state,
        appliedOffer: action.payload,
      };
    case SET_APPLIED_CART_OFFERS:
      return {
        ...state,
        appliedCartOffers: action.payload,
      };
    case SET_CART_PICKUP_DROP_PRICE:
      return {
        ...state,
        pickupDropPrice: action.payload,
      };
    case SET_CART_APPLIED_PICKUP_DROP_PRICE:
      return {
        ...state,
        appliedPickupDropPrice: action.payload,
      };
    case SET_CART_PACKAGES:
      return {
        ...state,
        addedPackages: action.payload,
      };
    case SET_CART_SELECTED_PICKUP_DROP:
      return {
        ...state,
        selectedPickupDrop: action.payload,
      };
    case SET_CART_JOURNEY:
      return {
        ...state,
        journeyType: action.payload,
      };
    case SET_CART_TYRE_PARAMETER:
      return {
        ...state,
        tyreQuotation: action.payload,
      };
    case SET_CART_BATTERY_PARAMETER:
      return {
        ...state,
        batteryQuotation: action.payload,
      };
    case SET_OB_POINTS:
      return {
        ...state,
        obPoints: action.payload,
      };
    case APPLY_OB_POINTS:
      return {
        ...state,
        isRedeemed: action.payload,
      };
    case SET_ORDER_SUCCESS:
      return {
        ...state,
        orderSuccess: action.payload,
      };
      case SET_SELECTED_AREA:
        return {
          ...state,
          selectedArea: action.payload,
        };
    case SET_SELECTED_QUOTATION_FORM:
      return {
        ...state,
        selectedQuotationForm: action.payload,
      };
    case SET_SHOW_MOBILE_ISLAND:
      return {
        ...state,
        showMobileIsland: action.payload,
      };
    case LOADING_PACKAGES:
      return {
        ...state,
        loadingPackages: action.payload,
      };
    case TOGGLE_MY_ACCOUNT:
      return {
        ...state,
        showMyAccount: action.payload,
      };
    case SET_OB_EARN_POINTS:
      return {
        ...state,
        obEarnPoints: action.payload,
      };
    case SET_OB_BURN_POINTS:
      return {
        ...state,
        obBurnPoints: action.payload,
      };
    case SET_APPLIED_PROMOTIONS:
      return {
        ...state,
        appliedPromotions: action.payload,
      };
    case REMOVE_APPLIED_PROMOTIONS:
      return {
        ...state,
        appliedPromotions: {},
      };
    case RESET_WORKSHOP:
      return {
        ...state,
        workshop: null,
      };

    case SHOW_LOADER:
      return {
        ...state,
        showLoader: action.payload,
      };

    case UPDATE_CART_JOB_ITEMS:
      return {
        ...state,
        jobItems: action.payload,
      };

    case UPDATE_CART_PRODUCTS:
      return {
        ...state,
        products: action.payload,
      };

    case FORMAT_RESET_STORE:
      return initState;

    default:
      return state;
  }
};

export default cartReducer;
