import { LOGIN_DATA, USER_AUTH, RESET_STORE, SET_PROFILE_LOADING } from "../actions/types";

const initState = {
  login: null,
  userAuth: null,
  loadingProfile: false,
};

const loginReducer = (state = initState, action) => {
  switch (action.type) {
    case LOGIN_DATA:
      return {
        ...state,
        login: action.payload,
      };
    case USER_AUTH:
      return {
        ...state,
        userAuth: action.payload,
      };
    case SET_PROFILE_LOADING:
      return {
        ...state,
        loadingProfile: action.payload,
      };
    case RESET_STORE:
      return initState;

    default:
      return state;
  }
};

export default loginReducer;
